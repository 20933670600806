<template>
  <div>
    <b-row>
      <b-col>
        <b-card title="Taux de disponibilité par actions">
          <!-- input search -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Recherche
                </label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Recherche"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
            <!-- primary -->
            <b-dropdown
              id="dropdown-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              text="Exporter"
              variant="primary"
              class="ml-2 btn_export"
            >
              <b-dropdown-item @click="exportCSV">
                CSV
              </b-dropdown-item>
              <b-dropdown-item @click="exportExcel">
                MS-Excel
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <vue-good-table
            id="list"
            ref="myTable"
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Status -->
              <span v-if="props.column.field === 'status'">
                <b-badge :variant="statusVariant(props.row.status)">
                  {{ props.row.status }}
                </b-badge>
              </span>
              <span v-if="props.column.field === 'taux_disponibilite'">
                <b-badge variant="primary">
                  {{ props.row.taux_disponibilite }} %
                </b-badge>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  :to="{ name: 'tourners-visite-details', params: { id: props.row.id } }"
                >
                  Voir
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  class="ml-1"
                  @click="handleDelete(props.row.id)"
                >
                  Supprimer
                </b-button>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Affichage de 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> sur {{ props.total }} élements </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>
      <b-col>
        <donut-chart
          v-if="chartData.length"
          :data="chartData"
          title="Représentation graphique"
          :value-field="'Effectuee'"
          :category-field="'Actions'"
          class="mt-5"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BBadge, BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import VueGoodTable from 'vue-good-table/src/components/Table.vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import htmlToPaper from 'vue-html-to-paper'
import { API_URL } from '@/helpers/global-scops'
import showToast from '@/helpers/methodes'
import DonutChart from '@/views/m-components/charts/DonutChart.vue'

export default {
  name: 'TauxDispoParActionListeMainVue',
  components: {
    DonutChart,
    BButton,
    BBadge,
    VueGoodTable,
    BCard,
    BCol,
    BRow,
    BDropdown,
    BFormInput,
    BDropdownItem,
    BFormGroup,
    BFormSelect,
    BPagination,
  },
  directives: {
    Ripple,
    htmlToPaper,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    tourner: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      rows: [],
      chartData: [],
      visites: [
      ],
      types: [
      ],
      tourneData: {
      },
      pageLength: 10,
      searchTerm: '',
      columns: [
        {
          label: 'Action',
          field: 'action.nom',
        },
        {
          label: 'Score',
          field: 'score_totale',
          type: 'number',
        },
        {
          label: 'Taux',
          field: 'taux_disponibilite',
        },
      ],
      arrondissements: [
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  created() {
    this.getTauxDisponibiliteAction()
  },
  methods: {
    test() {
      this.$http.get(`${API_URL}commes/?zone=2`)
        .then(response => {
          console.log(response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    exportExcel() {
    },
    exportCSV() {
      const header = this.columns.map(column => column.label)
      const data = this.rows.map(row => this.columns.map(column => row[column.field]))
      let csvData = `${header.join(',')}\n`
      csvData += data.map(row => row.join(',')).join('\n')
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', `${this.filename}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    getVisites() {
      this.$http.get(`${API_URL}visites/?tourner_id=${this.$route.params.id}`)
        .then(response => {
          // met dans visites les pdv du commercial et qui ne sont pas dans rows
          this.visites = response.data
          // this.visites = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    handleDelete(id) {
      // eslint-disable-next-line no-restricted-globals,no-alert
      if (confirm('Êtes-vous sûr de vouloir supprimer cet élément ?')) {
        this.$http.delete(`${API_URL}visites/${id}/`).then(response => {
          console.log(response.data)
          showToast(this, 'danger', 'Suppression', 'Element supprimé')
          this.getVisites()
        }).catch(error => {
          console.log(error)
        })
      } else {
        // Annuler la suppression ici
      }
    },
    getTauxDisponibiliteAction() {
      this.$http.get(`${API_URL}taux-disponibilite-action/${this.$route.params.id}/`)
        .then(response => {
          this.rows = response.data

          this.rows.forEach(row => {
            this.chartData.push({
              Actions: row.action.nom,
              Effectuee: row.score_totale,
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>

</style>
