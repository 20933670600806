<template>
  <div>
    <b-card>
      <b-row>

        <!-- User Info: Left col -->
        <b-col
          cols="6"
          md="4"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="tourneData.avatar"
              :text="avatarText(`${tourneData.prenom} ${tourneData.nom}`)"
              :variant="`light-${resolveUserRoleVariant(tourneData.role)}`"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ tourneData.prenom }} {{ tourneData.nom }}
                </h4>
                <span class="card-text">{{ tourneData.telephone }}</span>
              </div>
              <div class="d-flex flex-wrap">
                <b-button-group>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-secondary"
                    :to="{ name: 'commerciaux-visite-pdv', params: { commercial_id: tourneData.commercial, tournee_id: tourneData.id} }"
                  >
                    Faire une visite PDV
                  </b-button>
                </b-button-group>
              </div>
            </div>
          </div>

        </b-col>

        <!-- Right Col: Table -->
        <b-col
          md="3 "
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imageResponsable"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Nombre de visites effectuées </span>
                <b-badge variant="light-success">
                  {{ tourneData.nbr_visites }} / {{ tourneData.totals }}
                </b-badge>
              </th>
            </tr>
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imageCommerciaux"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Nombre de cycles effectué </span>
                <b-badge variant="light-success">
                  {{ tourneData.nbr_cycle }}
                </b-badge>
              </th>
            </tr>
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imagePDV"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Nombre de PDV : </span>
                <b-badge variant="light-success">
                  {{ tourneData.nbr_pdv }}
                </b-badge>
              </th>
            </tr>
          </table>
        </b-col>
        <b-col
          cols="6"
          md="4"
        >
          <vue-apex-charts
            v-if="tourneData.nbr_visites"
            height="120"
            :options="earningsChart.chartOptions"
            :series="[tourneData.nbr_visites, tourneData.totals - tourneData.nbr_visites]"
          />
          <h4 class="mb-0 text-center">
            Taux de realisation
          </h4>
        </b-col>
      </b-row>
    </b-card>
    <b-card>

      <b-tabs
        pills
      >

        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <span class="tab-image-icon">
              <img
                width="20px"
                :src="imageCommerciaux"
                alt="Description de l'image"
              >
            </span>

            <span class="d-none d-sm-inline ml-1">PDV de la tournée </span>
          </template>
          <PDVListeMainVue />
        </b-tab>
        <b-tab>
          <template #title>
            <span class="tab-image-icon">
              <img
                width="20px"
                :src="imageCommerciaux"
                alt="Description de l'image"
              >
            </span>

            <span class="d-none d-sm-inline ml-1">Les visites de la tournée </span>
          </template>
          <VisiteListeMainVue />
        </b-tab>
        <b-tab>
          <template #title>
            <span class="tab-image-icon">
              <img
                width="20px"
                :src="imagePDV"
                alt="Description de l'image"
              >
            </span>

            <span class="d-none d-sm-inline ml-1" title="Taux de disponibilité par produit Afridia">TDPA </span>
          </template>
          <TauxDispoParProduitAListeMainVue />
        </b-tab>
        <b-tab>
          <template #title>
            <span class="tab-image-icon">
              <img
                width="20px"
                :src="imagePDV"
                alt="Description de l'image"
              >
            </span>

            <span
              class="d-none d-sm-inline ml-1"
              title="Taux de disponibilité par produits Concurrents"
            >TDPC </span>
          </template>
          <TauxDispoParProduitCListeMainVue />
        </b-tab>
        <b-tab>
          <template #title>
            <span class="tab-image-icon">
              <img
                width="20px"
                :src="imagePDV"
                alt="Description de l'image"
              >
            </span>

            <span
              class="d-none d-sm-inline ml-1"
              title="Taux de disponibilité par actions"
            >TDA </span>
          </template>
          <TauxDispoParActionListeMainVue />
        </b-tab>
        <b-tab>
          <template #title>
            <span class="tab-image-icon">
              <img
                width="20px"
                :src="imageTourners"
                alt="Description de l'image"
              >
            </span>

            <span class="d-none d-sm-inline ml-1">Les statistiques </span>
          </template>
          <div class="mt-5" />
          <div class="mt-5" />
          <b-row v-if="tourneData.total_visitedispojus">
            <b-col
              md="6"
            >
              <donut-chart
                :data="[
                  {
                    category: 'Disponibles',
                    value: tourneData.total_visitedispojus,
                  },
                  {
                    category: 'Non disponibles',
                    value: tourneData.total_visitedispojus_non_dispo,
                  },
                ]"
                title="Taux de disponibilité des produits Afridia"
                :value-field="'value'"
                :category-field="'category'"
              />
            </b-col>
            <b-col
              md="6"
            >
              <donut-chart
                :data="[
                  {
                    category: 'Disponibles',
                    value: tourneData.total_visitedispojus_concurrence,
                  },
                  {
                    category: 'Non disponibles',
                    value: tourneData.total_visitedispojus_concurrence_non_dispo,
                  },
                ]"
                title="Taux de disponibilité des produits Concurrents"
                :value-field="'value'"
                :category-field="'category'"
              />
            </b-col>
          </b-row>
<!--          <b-row>-->
<!--            <b-col md="12">-->
<!--              <b-card title="Taux de disponiblité par produit">-->
<!--                <stacked-column-chart-->
<!--                  :data="data"-->
<!--                  category-field="channel"-->
<!--                  :series="series"-->
<!--                  x-axis-title="Canal de distribution"-->
<!--                  y-axis-title="Quantités à réapprovisionner"-->
<!--                />-->
<!--              </b-card>-->
<!--            </b-col>-->
<!--          </b-row>-->
        </b-tab>
      </b-tabs>
    </b-card>
  </div>

</template>

<script>
import {
  BAvatar, BBadge, BButton, BButtonGroup, BCard, BCol, BRow, BTab, BTabs,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useUsersList from '@/views/apps/user/users-list/useUsersList'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { API_URL } from '@/helpers/global-scops'
import VisiteListeMainVue from '@/views/afridia/tourners/details/VisiteListeMainVue.vue'
import PDVListeMainVue from '@/views/afridia/tourners/details/PDVListeMainVue.vue'
import DonutChart from '@/views/m-components/charts/DonutChart.vue'
import TauxDispoParProduitAListeMainVue from '@/views/afridia/tourners/details/TauxDispoParProduitAListeMainVue.vue'
import Ripple from 'vue-ripple-directive'
import htmlToPaper from 'vue-html-to-paper'
import TauxDispoParProduitCListeMainVue from '@/views/afridia/tourners/details/TauxDispoParProduitCListeMainVue.vue'
import TauxDispoParActionListeMainVue from '@/views/afridia/tourners/details/TauxDispoParActionListeMainVue.vue'
import StackedColumnChart from '@/views/m-components/charts/StackedColumnChart.vue'

const $earningsStrokeColor2 = 'rgb(3,147,255)'
const $earningsStrokeColor3 = 'rgba(3,147,255,0.28)'

const $apearningsStrokeColor2 = 'rgb(1,132,38)'
const $apearningsStrokeColor3 = 'rgba(42,111,57,0.31)'

export default {
  name: 'MainVue',
  components: {
    StackedColumnChart,
    TauxDispoParActionListeMainVue,
    TauxDispoParProduitCListeMainVue,
    BButtonGroup,
    TauxDispoParProduitAListeMainVue,
    DonutChart,
    VisiteListeMainVue,
    BBadge,
    PDVListeMainVue,
    BTabs,
    BTab,
    BButton,
    BAvatar,
    BRow,
    BCol,
    BCard,
    VueApexCharts,
  },
  directives: {
    Ripple,
    htmlToPaper,
  },
  data() {
    return {
      tauxDispoParProduitA: [],
      data: [
        {
          channel: 'Boutique en ligne', product1: 150, product2: 80, product3: 90,
        },
        {
          channel: 'Magasin', product1: 120, product2: 150, product3: 110,
        },
        {
          channel: 'Marché', product1: 80, product2: 100, product3: 70,
        },
      ],
      series: [
        { seriesName: 'Produit 1', valueField: 'product1', color: '#4CAF50' },
        { seriesName: 'Produit 2', valueField: 'product2', color: '#2196F3' },
        { seriesName: 'Produit 3', valueField: 'product3', color: '#FFC107' },
      ],
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: true,
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: { show: true },
          comparedResult: [47, 53],
          labels: ['Effectuées', 'Restants'],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'Visites',
                    formatter() {
                      return '24'
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
      dispoAfridiaProductsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: true,
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: { show: true },
          comparedResult: [47, 53],
          labels: ['Disponibles', 'Non disponibles'],
          stroke: { width: 0 },
          colors: [$apearningsStrokeColor2, $apearningsStrokeColor3, $themeColors.success],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'Tournées',
                    formatter() {
                      return '100%'
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
      tourneData: { },
      // eslint-disable-next-line global-require
      imageDistributeurs: require('@/assets/images/icons/box.png'),
      // eslint-disable-next-line global-require
      imageCommerciaux: require('@/assets/images/icons/team.png'),
      // eslint-disable-next-line global-require
      imageResponsable: require('@/assets/images/icons/user-gear.png'),
      // eslint-disable-next-line global-require
      imageTourners: require('@/assets/images/icons/tourners.png'),
      // eslint-disable-next-line global-require
      imagePDV: require('@/assets/images/icons/store.png'),
      userData: {},
    }
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
  mounted() {
    this.getTourneData()
  },
  methods: {
    getTourneData() {
      this.$http.get(`${API_URL}tourner/${this.$route.params.id}/`)
        .then(response => {
          this.tourneData = response.data
          this.dispoAfridiaProductsChart = {
            chartOptions: {
              chart: {
                type: 'donut',
                toolbar: {
                  show: true,
                },
              },
              dataLabels: {
                enabled: true,
              },
              legend: { show: true },
              comparedResult: [47, 53],
              labels: ['Disponibles', 'Non disponibles'],
              stroke: { width: 0 },
              colors: [$apearningsStrokeColor2, $apearningsStrokeColor3, $themeColors.success],
              grid: {
                padding: {
                  right: -20,
                  bottom: -8,
                  left: -20,
                },
              },
              plotOptions: {
                pie: {
                  startAngle: 0,
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        offsetY: 15,
                      },
                      value: {
                        offsetY: -15,
                        formatter(val) {
                          // eslint-disable-next-line radix
                          return `${parseInt(val)}%`
                        },
                      },
                      total: {
                        show: true,
                        offsetY: 15,
                        label: 'Tournées',
                        formatter() {
                          return `100%`
                        },
                      },
                    },
                  },
                },
              },
              responsive: [
                {
                  breakpoint: 1325,
                  options: {
                    chart: {
                      height: 100,
                    },
                  },
                },
                {
                  breakpoint: 1200,
                  options: {
                    chart: {
                      height: 120,
                    },
                  },
                },
                {
                  breakpoint: 1045,
                  options: {
                    chart: {
                      height: 100,
                    },
                  },
                },
                {
                  breakpoint: 992,
                  options: {
                    chart: {
                      height: 120,
                    },
                  },
                },
              ],
            },
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    calculerPourcentage(petitNombre, grandNombre) {
      return (petitNombre / grandNombre) * 100
    },
    getTauxDisponibilite() {
      this.$http.get(`${API_URL}taux-disponibilite-produit/${this.$route.params.id}/`)
        .then(response => {
          this.tauxDispoParProduitA = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>

</style>
